.background-1 {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../public/images/egitim-koprusu.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 80vh;
}

.icon-register {
  width: 50px;
  height: 50px;
  padding: 5px;
}

.icon-support {
  width: 50px;
  height: 50px;
  padding: 5px;
}

.icon-finished {
  width: 50px;
  height: 50px;
  padding: 5px;
}

.card-hover:hover {
  position: relative;
  bottom: 10px;
  box-shadow: 5px 5px 5px 5px gray;
  font-weight: 400;
}
