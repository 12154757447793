.i-facebook {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.href-facebook {
  text-decoration: none;
  color: black;
}

.href-facebook:hover {
  color: blue;
}

.i-instagram {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.href-instagram {
  text-decoration: none;
  color: black;
}

.href-instagram:hover {
  color: purple;
}

.i-threads {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.href-threads {
  text-decoration: none;
  color: black;
}

.href-threads:hover {
  color: orange;
}

.i-twitter {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.href-twitter {
  text-decoration: none;
  color: black;
}

.href-twitter:hover {
  color: rgb(7, 129, 230);
}

.f-list {
  list-style-type: none;
}

@media (max-width: 576px) {
  .navbar .navbar-nav .nav-link {
    display: none;
  }
  .navbar .navbar-nav .nav-link:first-child {
    display: block;
  }
}
